import { Maybe, OptionItem } from '@/common/types';
import type { HierarchicalAsset_AssetFragment } from '../hooks/useHierarchicalAssets.generated';
import { IAsset, IDisplayAsset } from '../types';

export const buildAssetDescription = (assets: IAsset[]): string => {
  return assets.map(formatAssetName).join('\n');
};

export const formatAssetName = (asset: Maybe<IDisplayAsset> | null): string => {
  if (!asset) return '';
  return asset.parentAsset ? `${formatAssetName(asset.parentAsset)} > ${asset.name}` : asset.name;
};

export const getAssetNameList = (
  asset: Maybe<IDisplayAsset>,
  nameList: string[] = []
): string[] => {
  if (!asset) return nameList;
  const output = [asset.name, ...nameList];
  return asset.parentAsset ? getAssetNameList(asset.parentAsset, output) : output;
};

export const adjustAssetsForSelect = (asset: HierarchicalAsset_AssetFragment): OptionItem => {
  const { id, name: label, childAssets: children } = asset;

  if (children && children.length > 0) {
    return {
      id,
      label,
      children: children.map(adjustAssetsForSelect),
    };
  }
  return {
    id,
    label,
  };
};

export const filterAssetsForSelect = (
  targetAssetId: number,
  selectAssets: OptionItem[]
): OptionItem[] => {
  const filteredSelectAssets = selectAssets.reduce<OptionItem[]>((acc, asset) => {
    if (asset.id !== targetAssetId) {
      const filteredAsset = { ...asset };
      if (asset.children) {
        filteredAsset.children = filterAssetsForSelect(targetAssetId, asset.children);
      }
      acc.push(filteredAsset);
    }
    return acc;
  }, []);
  return filteredSelectAssets;
};

export const getAssetLabelById = (id: string, assets: OptionItem[]): string => {
  function searchRecursively(items: OptionItem[]): string {
    for (const item of items) {
      if (Number(item.id) === Number(id)) {
        return item.label;
      }

      if (item.children) {
        const foundLabel = searchRecursively(item.children);
        if (foundLabel) {
          return foundLabel;
        }
      }
    }

    return '';
  }

  return searchRecursively(assets);
};
